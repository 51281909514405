

import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination } from '@mui/material';

import { useGetAllApplicationsQuery } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';
import { useEffect, useState } from "react";
import moment from 'moment/moment';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';

import { useAppSoftDeleteMutation } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';
import { useAppPermanentDeleteMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { useSelector, useDispatch } from 'react-redux';


const TrashApplications = () => {
  const { access_token } = useSelector(state => state.auth);

    const navigate = useNavigate();
    const [allApplications, setAppAlllications] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(true);

    const { data, isSuccess, refetch, isLoading } = useGetAllApplicationsQuery(access_token);
    let srNo = 0;
    let count = false;

    useEffect(() => {
        if (data && isSuccess) {
            const loadingTimer = setTimeout(() => {
                setAppAlllications(data);
                setIsLoadingData(false);
                refetch();
            }, 1000);

            return () => clearTimeout(loadingTimer);
        }
    }, [data, isSuccess]);

    const [updateResource, { isError }] = useAppSoftDeleteMutation();

    const onRestoreBtn = async (appId) => {
        const formData = { is_delete: false, show_ads: true };
        const x = window.confirm('Restore this application ?');

        if (x === true) {
            const res = await updateResource({ appId, formData ,access_token});

            if (res.data) {
                refetch();
            }

            if (res.error) {
                alert('Something went wrong');
            }
        }
    };

    const [deleteResource] = useAppPermanentDeleteMutation();

    const onDeleteBtn = async (appId) => {
        const x = window.confirm('Permanently delete this application ?');

        if (x === true) {

            const resp = await deleteResource({appId , access_token});
            refetch();

            if (resp.data) {
                refetch();
            }

            if (resp.error) {
                alert('Something went wrong');
            }
        }
    };

    return (
        <>
            <Box>
                <Box sx={{ mx: 2, mt: 2 }}>
                    <Box>
                        <Typography color="primary">
                            <NavLink to="/app-lists" sx={{ color: 'primary' }}>
                                App Lists
                            </NavLink>{' '}
                            / Trash Application
                        </Typography>
                        <hr color="lightgray" />
                    </Box>
                </Box>
            </Box>

            <Box sx={{ mx: 2, mt: 5 }}>
                <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>
                    <Typography sx={{ mx: 1, my: 2 }} color="primary">
                        All Trash Application List
                    </Typography>

                    <Box>
                        <Box className="table-wrapper" style={{ maxHeight: '700px', overflowY: 'scroll' }}>
                            {isLoadingData ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300 }}>
                                    <CircularProgress />
                                    <Typography sx={{ ml: 2 }}>Loading apps...</Typography>
                                </Box>
                            ) : (
                                <Box>
                                    <TableContainer>
                                        <Table className="table table-striped">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography>App Id</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>App Logo</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography sx={{ marginLeft: 5 }}>App Name</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>Other Details</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography sx={{ marginLeft: 5 }}>Action</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {allApplications.map((row) =>
                                                    row.is_delete ? (
                                                        <TableRow key={row.id}>
                                                            <TableCell>
                                                                {srNo += 1} <span style={{ display: 'Hidden' }}>{count = true}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <img src={row.app_logo} alt={row.title} height="60px" className="border border-1 rounded-circle" style={{ boxShadow: "2px 2px 5px gray" }}/>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{row.app_name}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography sx={{ backgroundColor: 'lightblue', borderRadius: 1, p: 1 }}>{row.package_name}</Typography>
                                                                <Typography sx={{ fontSize: '11px' }}>Created date: {moment(row.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                                <Typography sx={{ fontSize: '11px' }}>Last update: {moment(row.updated_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button sx={{ color: 'primary', border: 1, mx: 1 }} onClick={() => { onRestoreBtn(row.id) }}>
                                                                    <RestoreOutlinedIcon />
                                                                </Button>
                                                                <Button sx={{ color: 'red', border: 1 }} onClick={() => { onDeleteBtn(row.id) }}>
                                                                    <DeleteIcon />
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : ''
                                                )}

                                                {count ? (
                                                    ''
                                                ) : (
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell>
                                                            <Typography sx={{ p: 2, color: 'gray' }}>No Trash Application</Typography>
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default TrashApplications;
