import React from 'react';

import { Button, Box, Typography, Grid, Select, MenuItem, Alert, Tabs, Tab, CircularProgress, capitalize } from "@mui/material";
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';

import { useGetApplicationsDetailsQuery } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';

import { useAppFieldUpdateMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { useEffect, useState } from 'react';
// time formate 
import moment from 'moment/moment';

import { useAppSoftDeleteMutation } from '../../ApiCalls/AuthenticationsApi/services/userAuthApi';

// get monetize plateform dta 

import { useGetAdsMonetizePlatformsQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import Multiselect from 'multiselect-react-dropdown';

import { CSSTransition } from 'react-transition-group';
import NotificationPanel from './NotificationPanel';

import { useSelector, useDispatch } from 'react-redux';
import { Label } from '@mui/icons-material';


const TabPanel = (props) => {
    const { children, value, index } = props;

    return (
        <div role='tabpanel' hidden={value !== index}>
            {
                value === index && (<Box>{children}</Box>)
            }

        </div>
    )
}


const ApplicationDetails = () => {
    const { access_token } = useSelector(state => state.auth);

    const navigate = useNavigate()

    const { id } = useParams();
    const [appData, setAppData] = useState([])

    const [appName, setAppName] = useState('')
    const [packageName, setPackageName] = useState('')

    const [appLogo, setAppLogo] = useState(null)

    const [appOpenAds, setAppOpenAds] = useState({})
    const [bannerAds, setBannerAds] = useState('')
    const [nativeAds, setNativeAds] = useState('')
    const [interstitialAds, setInterstitialAds] = useState('')
    const [rewardedAds, setRewardedAds] = useState('')
    const [mobileStickyAds, setMobileStickyAds] = useState('')
    const [collapsibleAds, setCollapsibleAds] = useState('')
    const [customAdsJson, setCustomAdsJson] = useState({ "key": "value" })
    const [extraDataField, setExtraDataField] = useState({ "key": "value" })
    const [adsCount, setAdsCount] = useState('')
    const [adsOpenType, setAdsOpenType] = useState('')
    // const [adsEnableToggle, setAdsEnableToggle] = useState(null)
    const [adsType, setAdsType] = useState('')
    const [appStatus, setAppStatus] = useState('')

    const [versionName, setVersionName] = useState('')
    // Ads Type
    const [adsTypeSequence, setAdsTypeSequence] = useState('')


    // test ads platform 
    const [testAdsPlatform, setTestAdsPlatform] = useState('')

    // Ads Colors 
    const [buttonBg, setButtonBg] = useState('')
    const [buttonTextColor, setButtonTextColor] = useState('')
    const [commonTextColor, setCommonTextColor] = useState('')
    const [adsBgColor, setAdsBgColor] = useState('')





    // error states 

    const [customJsonError, setCustomJsonError] = useState('')
    const [extraDataJsonError, setExtraDataJsonError] = useState('')
    const [imageLargeError, setImageLargeError] = useState('')
    const [adsCountError, setAdsCountError] = useState('')
    const [versionNameError, setVersionNameError] = useState('')
    const [value, setValue] = useState(0);
    const [backPressAdsCountError, setBackPressAdsCountError] = useState('')


    // Monetize Ads tabs 
    const [monetizePlatform, setMonetizePlatform] = useState([])
    const [tabValue, setTabValue] = useState(0);

    // platform select 
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [monetizeName, setMonetizeName] = React.useState([]);

    // backpress ads 
    const [backPressAdsType, setBackPressAdsType] = useState('')
    const [backPressAdsCount, setBackPressAdsCount] = useState('')

    // const [updateAppDetails, { isError }] = useAppFieldUpdateMutation()
    const { data: appDetails, isSuccess, refetch, isLoading } = useGetApplicationsDetailsQuery({ id, access_token })
    const [updateResource, { isError }] = useAppSoftDeleteMutation()

    // download animation 
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);


    let srNo = -1;

    // const options = ['Admob' , 'FaceBook' , 'Adx'];

    // get Monetize platform Data 
    const { data: getMonetize, isSuccess: getMonetizeSuccess, refetch: monetizeRefetch } = useGetAdsMonetizePlatformsQuery(access_token)

    useEffect(() => {
        if (getMonetize && getMonetizeSuccess) {
            setMonetizePlatform(getMonetize)

            monetizeRefetch()
            setTabValue(getMonetize[0]["id"] - 1) // to set monetize platform initial state 
            // console.log('tabValue :',tabValue)
            setSelectedOptions(getMonetize.map((platform) => platform.monetize_platformname))


            if (localStorage.previousUrl === '/app-notifications') {
                setValue(2)
                localStorage.removeItem('previousUrl')
            }

        }
    }, [getMonetize, isSuccess])


    // console.log('monetizePlatform :',monetizePlatform)


    useEffect(() => {
        if (appDetails && isSuccess) {
            setShowLoadingAnimation(true);

            setAppData(appDetails)
            setAppName(appDetails.app_name);
            setPackageName(appDetails.package_name)
            setAppOpenAds(appDetails.app_open_ads)
            setBannerAds(appDetails.banner_ads)
            setNativeAds(appDetails.native_ads)
            setInterstitialAds(appDetails.interstitial_ads)
            setRewardedAds(appDetails.rewarded_ads)
            setMobileStickyAds(appDetails.mobile_sticky_ads)
            setCollapsibleAds(appDetails.collapsible_ads)
            setCustomAdsJson(JSON.stringify(appDetails.custom_ads_json, null, 2))
            setExtraDataField(JSON.stringify(appDetails.extra_data_field, null, 2))
            setAdsCount(appDetails.ads_count)
            setAdsOpenType(appDetails.ads_open_type)
            setVersionName(appDetails.version_name)
            setAppStatus(appDetails.app_status)
            // setAdsEnableToggle(appDetails.show_ads)
            setAdsType(appDetails.ads_type)
            setMonetizeName(appDetails.monetize_platform)
            setAdsTypeSequence(appDetails.ads_sequence_type)

            setTestAdsPlatform(appDetails.testads_platform)

            // set ads color
            setButtonBg(appDetails.button_bg)
            setButtonTextColor(appDetails.button_text_color)
            setCommonTextColor(appDetails.common_text_color)
            setAdsBgColor(appDetails.ads_bg)

            // back press ads
            setBackPressAdsType(appDetails.backpress_ads_type)
            setBackPressAdsCount(appDetails.backpress_count)


            refetch()
            monetizeRefetch()
            setTimeout(() => {
                setShowLoadingAnimation(false);
            }, 1000);

            // console.log('appDetails.testads_platform :', appDetails.testads_platform)
        }

    }, [appDetails, isSuccess])






    const handleAppsDetails = async (appId) => {

        const formData = new FormData()

        if (appName) {
            formData.append('app_name', appName)
        }


        if (appLogo !== null) {
            formData.append('app_logo', appLogo)
        }

        // app open ads 
        if (appOpenAds === '') {
            formData.append('app_open_ads', 'No Ads')
        } else {
            formData.append('app_open_ads', JSON.stringify(appOpenAds))
        }

        // banmer ads
        if (bannerAds === '') {
            formData.append('banner_ads', 'No Ads')
        } else {
            formData.append('banner_ads', JSON.stringify(bannerAds))
        }

        // native ads 
        if (nativeAds === '') {
            formData.append('native_ads', 'No Ads')
        } else {
            formData.append('native_ads', JSON.stringify(nativeAds))
        }

        // interstitialAds
        if (interstitialAds === '') {
            formData.append('interstitial_ads', 'No Ads')
        } else {
            formData.append('interstitial_ads', JSON.stringify(interstitialAds))
        }

        // rewardedAds
        if (rewardedAds === '') {
            formData.append('rewarded_ads', 'No Ads')
        } else {
            formData.append('rewarded_ads', JSON.stringify(rewardedAds))
        }

        // mobileStickyAds
        if (mobileStickyAds === '') {
            formData.append('mobile_sticky_ads', 'No Ads')
        } else {
            formData.append('mobile_sticky_ads', JSON.stringify(mobileStickyAds))
        }

        // collapsibleAds
        if (collapsibleAds === '') {
            formData.append('collapsible_ads', 'No Ads')
        } else {
            formData.append('collapsible_ads', JSON.stringify(collapsibleAds))
        }


        // customAdsJson json data 
        if (customAdsJson === "") {
            formData.append('custom_ads_json', '{}')
        } else {
            formData.append('custom_ads_json', customAdsJson)
        }


        // extra_data_field
        if (extraDataField === "") {
            formData.append('extra_data_field', '{}')
        } else {
            formData.append('extra_data_field', extraDataField)

        }



        if (adsCount) {
            formData.append('ads_count', adsCount)
            // console.log('ads_count :', adsCount)
        }

        if (versionName) {
            formData.append('version_name', versionName)
            // console.log('ads_count :', adsCount)
        }


        formData.append('ads_open_type', adsOpenType)

        formData.append('ads_type', adsType)


        if (monetizeName === "null" || !monetizeName || monetizeName == "" || monetizeName.length === 0) {
            formData.append('monetize_platform', "Adx")


        } else {
            formData.append('monetize_platform', monetizeName)


        }


        formData.append('ads_sequence_type', adsTypeSequence)


        formData.append('app_status', appStatus)
        // formData.append('app_status', appStatus)


        formData.append('testads_platform', testAdsPlatform)

        // button 
        formData.append('button_bg', buttonBg)
        formData.append('button_text_color', buttonTextColor)
        formData.append('common_text_color', commonTextColor)
        formData.append('ads_bg', adsBgColor)

        formData.append('backpress_ads_type', backPressAdsType)
        formData.append('backpress_count', backPressAdsCount)





        // console.log('monetizeName :',monetizeName)
        // show ads 

        // formData.append('show_ads', adsEnableToggle)


        // making patch request 
        let serverError;
        const res = await updateResource({ appId, formData, access_token })
        // console.log(res)
        if (res.data) {
            navigate('/app-lists')
        }
        else if (res.error.data) {
            // console.log('res.error :', res.error.error)
            serverError = res.error.data


            if (serverError.custom_ads_json) {
                setCustomJsonError(serverError.custom_ads_json)

            }

            else if (serverError.extra_data_field) {
                setExtraDataJsonError(serverError.extra_data_field)
            }


        }
        else if (res.error.error) {
            if (res.error.error) {
                setImageLargeError("Uploaded Image is too larg")
            }
        }

    }

    const handleApplogoChange = (e) => {
        setAppLogo(e.target.files[0]);
    };


    // change tabs
    const handlerChange = (event, newValue) => {

        // if appdetails call from notification tab will be set notification settings 
        setValue(newValue)

    }


    // Monetize Ads tabs 
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        // tabValue, setTabValue
    };


    // multiselect 
    const handleMultiSelectChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };


    //=================================== Notification Logic Start from here ============================ 
    // const [notificationTabValue, setNotificationTabValue] = useState(0)

    // const notificationTabHandler = (event, notificationTabValue) => {
    //     setNotificationTabValue(notificationTabValue);
    //     // tabValue, setTabValue
    // };


    // handle ads Toggle
    // handle ads enable togle 
    const handleShowAdsToggle = async (appId, adsStatus) => {
        const adsState = !adsStatus;
        const formData = { show_ads: adsState };
        const res = await updateResource({ appId, formData, access_token });
        // console.log(res)
        if (res.data) {
            // setShowLoadingAnimation(true);

            refetch();
            // setTimeout(() => {
            //     setShowLoadingAnimation(false);
            // }, 1000);
        }
        if (res.error) {
            alert('Something went wrong.');
        }
    };









    return (

        <>
            <Box>
                <Box>
                    <Box sx={{ mx: 2, mt: 2 }} >
                        <div>
                            <Typography color="primary"><NavLink to='/app-lists' sx={{ color: 'primary' }}>App Lists</NavLink> </Typography>
                            <hr color="lightgray" />
                        </div>
                    </Box>
                </Box>



                {isLoading || showLoadingAnimation ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                        <CircularProgress />
                        <Typography sx={{ ml: 2 }}>Loading apps...</Typography>
                    </Box>
                ) :


                    <Box sx={{ width: '98%', height: '100%', mx: 2 }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>


                            {/* added if the previous url /app-notifications ,then automatically tab will switch to notification settings  */}
                            <Tabs value={value} textColor="primary" indicatorColor="primary" onChange={handlerChange}>

                                <Tab label="Application Settings" sx={{ textTransform: 'none', fontWeight: 'bold' }}></Tab>
                                <Tab label="Monetize Settings" sx={{ textTransform: 'none', fontWeight: 'bold' }} ></Tab>
                                <Tab label="Notifications Settings" sx={{ textTransform: 'none', fontWeight: 'bold' }} ></Tab>

                            </Tabs>

                        </Box>

                        {/* ####################################################################################   Application Settings Tab Start From Here #################################################################################### */}


                        <TabPanel value={value} index={0} id="Settings-Tab">


                            {/* application details and Ads  */}
                            <Box sx={{ mt: 2, mb: 2, px: 2 }}>
                                <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>


                                    {/* ========================================= applications details Grid start here  =========================================  */}
                                    <Grid container spacing={2}>

                                        {/* ====================== Left applications details Grid ======================  */}
                                        <Grid item lg={6} xs={12} >

                                            <Box sx={{ p: 1 }}>

                                                {/* heading application  */}
                                                <Box sx={{ mb: 3 }}>
                                                    <Typography sx={{ p: 1, backgroundColor: 'lightblue', borderRadius: 1 }}>{appData.app_name}</Typography>
                                                </Box>

                                                {/* =================================================================== */}

                                                <Box sx={{ my: 3, pb: 1 }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item lg={4} xs={12}>
                                                            <img src={appData.app_logo} alt="No Logo" height='150px' style={{ border: '1px solid lightgray', borderRadius: '10px', boxShadow: "2px 2px 5px gray" }} id='app_logo' name='app_logo' className='img-fluid' />
                                                        </Grid>

                                                        <Grid item lg={8} xs={12}>

                                                            {/* application version    */}
                                                            <Grid container spacing={2}>
                                                                <Grid item lg={6} xs={12}>

                                                                    <label htmlFor="version_name" className="form-label">Application Version</label>
                                                                    <Box>
                                                                        <input type="text" name="version_name" id="version_name" className='form-control' style={{ color: 'gray' }} value={versionName} step="0.0"
                                                                            onChange={(e) => {
                                                                                if (e.target.value < 0) {
                                                                                    setVersionName(versionName)
                                                                                } else {
                                                                                    setAdsCountError('')
                                                                                    setVersionName(e.target.value)
                                                                                }
                                                                            }} />

                                                                    </Box>
                                                                </Grid>
                                                                <Grid item lg={6} xs={12}>
                                                                    <Box>
                                                                        {/* Ads Enable Toggle  */}
                                                                        <Box>

                                                                            <label className="form-label">Enable Ads</label>
                                                                        </Box>

                                                                        {appDetails.show_ads ? (
                                                                            <Box className="form-check form-switch" sx={{ display: "inline-block" }}>
                                                                                <input className="form-check-input" type="checkbox" id={`is_enable${appDetails.id}`} defaultChecked onClick={() => { handleShowAdsToggle(appDetails.id, appDetails.show_ads) }} />
                                                                            </Box>
                                                                        ) : (
                                                                            <Box className="form-check form-switch" sx={{ display: "inline-block" }}>
                                                                                <input className="form-check-input" type="checkbox" id={`is_enable${appDetails.id}`} onClick={() => { handleShowAdsToggle(appDetails.id, appDetails.show_ads) }} />
                                                                            </Box>
                                                                        )}

                                                                    </Box>
                                                                </Grid>

                                                            </Grid>






                                                            <Box sx={{ mt: 2 }}>
                                                                <label htmlFor="app_status" className='form-label'>Application Status</label>
                                                                <Box>
                                                                    <Select placeholder="Select App Statuse" sx={{ width: "100%", maxWidthh: 400, minWidth: 40, height: 40, }} id="app_status" name="ads_open_type" value={appStatus} onChange={(e) => setAppStatus(e.target.value)} >

                                                                        <MenuItem value='Not Published' >Not Published</MenuItem>
                                                                        <MenuItem value='Published'>Published</MenuItem>
                                                                        <MenuItem value='Suspended'>Suspended</MenuItem>
                                                                    </Select>


                                                                </Box>
                                                            </Box>


                                                        </Grid>


                                                    </Grid>

                                                </Box>

                                                {/* =================================================================== */}
                                                {/* Display app logo  */}
                                                {/* <Box sx={{ mb: 3 }}>
                                                <Box >
                                                    <img src={appData.app_logo} alt="No Logo" height='150px' style={{ border: '1px solid lightgray', borderRadius: '10px' }} id='app_logo' name='app_logo' />

                                                </Box>
                                            </Box> */}


                                                {/* change app logo  */}
                                                <Box sx={{ mb: 1 }}>
                                                    <Box >
                                                        <label htmlFor="app_logo" className='form-label'>Change Logo</label>

                                                        <input type="file" id="app_logo" name="app_logo" accept="image/*" onChange={handleApplogoChange} className="form-control" />
                                                        {imageLargeError ? <Typography sx={{ color: 'red' }}>{imageLargeError}</Typography> : ''}
                                                    </Box>
                                                </Box>

                                                <Box sx={{ backgroundColor: '', py: 2, borderRadius: 1 }}>

                                                    {/* application name  */}
                                                    <Box >
                                                        <label htmlFor="app_name" className='form-label'>Application Name</label>
                                                        <input type="text" id='app_name' name='app_name' className='form-control' value={appName} onChange={(e) => setAppName(e.target.value)} style={{ color: 'gray' }} />

                                                    </Box>

                                                </Box>

                                            </Box>


                                        </Grid>


                                        {/* ====================== Right applications details Grid ======================  */}

                                        <Grid item lg={6} xs={12}>

                                            <Box sx={{ p: 1, mt: 14 }}>

                                                {/* Application Platform  and application status  */}
                                                <Box sx={{ mb: 3 }}>
                                                    <Grid container spacing={2}>
                                                        {/* publish platform  */}
                                                        <Grid item lg={6} xs={12} >
                                                            <Typography >
                                                                {/* <span style={{ fontWeight: 'bold' }}>Application Platform   </span> */}
                                                                <label className='form-label'>Application Platform </label>
                                                                {/* <span style={{ color: 'gray' }}>{appData.app_platform}</span> */}
                                                                <Typography sx={{ boxShadow: '1px 2px 8px gray', display: 'inline-block', mx: 2, px: 2, py: 1, borderRadius: 5 }} style={{ color: 'gray' }}>{appData.app_platform}</Typography>

                                                            </Typography>
                                                        </Grid>

                                                        {/* application status  */}
                                                        <Grid item lg={6} xs={12} >
                                                            {appData.app_status === 'Not Published'
                                                                ?
                                                                <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5, width: 150 }} textAlign='center' style={{ color: 'gray' }}>{appData.app_status}</Typography>
                                                                :
                                                                appData.app_status === 'Published'
                                                                    ?
                                                                    <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, width: 150 }} textAlign='center' style={{ color: 'gray' }}>{appData.app_status}</Typography>
                                                                    :
                                                                    <Typography sx={{ boxShadow: '1px 2px 8px red', display: 'inline-block', p: 1, borderRadius: 5, width: 150 }} textAlign='center' style={{ color: 'gray' }}>{appData.app_status}</Typography>

                                                            }
                                                        </Grid>
                                                    </Grid>

                                                </Box>


                                                {/* application Downloads  */}
                                                <Box sx={{ mb: 3 }}>
                                                    <Grid container spacing={2}>

                                                        {/* left app downloads  */}

                                                        <Grid item lg={6} xs={12} >
                                                            <Typography sx={{ mt: 1 }}>
                                                                {/* <span style={{ fontWeight: 'bold' }}>Application Downloads : </span> */}
                                                                <label className='form-label me-2'>Application Downloads : </label>

                                                                <input type="text" id='downloads' name='downloads' className='form-control w-25 d-inline' value={appData.downloads} readOnly style={{ color: 'gray' }} />
                                                            </Typography>
                                                        </Grid>
                                                        {/* right date of added  */}
                                                        <Grid item lg={6} xs={12} >
                                                            <Box sx={{ mx: 3 }}>
                                                                <Typography sx={{ mt: 1, fontWeight: 'bold' }}>Created Date </Typography>
                                                                <Typography sx={{ mt: 1, fontSize: 11 }} style={{ color: 'gray' }}>{moment(appData.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                            </Box>

                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                {/* package name  */}
                                                <Box sx={{ mb: 3 }}>
                                                    <label htmlFor="package_name" className='form-label'>Package Name</label>
                                                    <input type="text" id='package_name' name='package_name' className='form-control' value={appData.package_name} readOnly style={{ color: 'gray' }} />

                                                </Box>

                                                {/* application added by  */}
                                                <Box sx={{ mb: 3 }}>
                                                    <label htmlFor="package_name" className='form-label'>Application Added By</label>
                                                    <input type="text" id='package_name' name='package_name' className='form-control' value={appData.added_user} readOnly style={{ color: 'gray' }} />

                                                </Box>

                                                {/* extra_data_field */}


                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {/* ========================================= applications details Grid end here =========================================  */}


                                    {/* ========================================================= Ads Color Start from here =========================================================  */}
                                    <Box sx={{ mb: 2, borderRadius: 1, p: 1, mx: 1, boxShadow: '2px 2px 8px lightgray' }}>
                                        <Box sx={{ borderBottom: '1px solid gray', p: 1, mb: 1, width: "10%" }}>
                                            <Typography sx={{ fontWeight: 'bold' }}>Ads Color</Typography>
                                        </Box>
                                        <Box >
                                            <Grid container spacing={2}>

                                                {/* button start color  */}
                                                <Grid item lg={3} md={6} xs={12} >
                                                    <Box sx={{ my: 1 }}>
                                                        <label className='form-label'>Button Background Color</label>
                                                        {/* <input type='text' className='form-control' id='button_bg' name='button_bg' placeholder='Add Color #00000' value={buttonBg} onChange={(e) => setButtonBg(e.target.value)} /> */}
                                                        <Box>
                                                            <input type="color" className="" value={buttonBg} onChange={(e) => setButtonBg(e.target.value)} style={{ width: "100%" }} />
                                                            <input type="text" className="form-control" placeholder="Enter a hex code" value={buttonBg} onChange={(e) => setButtonBg(capitalize(e.target.value))} />

                                                        </Box>

                                                    </Box>

                                                </Grid>

                                                {/* button end color  */}
                                                <Grid item lg={3} md={6} xs={12} >
                                                    <Box sx={{ my: 1 }}>
                                                        <label className='form-label'>Button Text Color</label>
                                                        <Box>
                                                            <input type="color" className="" value={buttonTextColor} onChange={(e) => setButtonTextColor(e.target.value)} style={{ width: "100%" }} />
                                                            <input type='text' className='form-control' id='button_text_color' name='button_text_color' placeholder='Add Color #FFFF' value={buttonTextColor} onChange={(e) => setButtonTextColor(e.target.value)} />
                                                        </Box>

                                                    </Box>

                                                </Grid>

                                                {/* button text color  */}
                                                <Grid item lg={3} md={6} xs={12} >
                                                    <Box sx={{ my: 1 }}>
                                                        <label className='form-label'>Common Text Color </label>
                                                        <Box>
                                                            <input type="color" className="" value={commonTextColor} onChange={(e) => setCommonTextColor(e.target.value)} style={{ width: "100%" }} />
                                                            <input type='text' className='form-control' id='common_text_color' name='common_text_color' placeholder='Add Color #FFFF' value={commonTextColor} onChange={(e) => setCommonTextColor(e.target.value)} />

                                                        </Box>

                                                    </Box>

                                                </Grid>

                                                {/* ads background */}
                                                <Grid item lg={3} md={6} xs={12} >
                                                    <Box sx={{ my: 1 }}>
                                                        <label className='form-label'>Ads Background Color</label>
                                                        <Box>
                                                            <input type="color" className="" value={adsBgColor} onChange={(e) => setAdsBgColor(e.target.value)} style={{ width: "100%" }} />
                                                            <input type='text' className='form-control' id='ads_bg_color' name='ads_bg_color' placeholder='Add Color #FFFF' value={adsBgColor} onChange={(e) => setAdsBgColor(e.target.value)} />
                                                        </Box>

                                                    </Box>

                                                </Grid>
                                            </Grid>
                                        </Box>

                                    </Box>
                                    {/* ========================================================= Ads Color end here =========================================================  */}





                                    {/* ========================================= Extra data field and json field start from here  =========================================*/}
                                    <Box sx={{ borderTop: "1px solid lightgray", py: 2 }}>

                                        {/* extra json data  */}
                                        <Grid container spacing={2}>
                                            {/* publish platform  */}
                                            <Grid item lg={6} xs={12} >

                                                <Box sx={{ p: 1 }}>
                                                    {/*================================== Left Grid custom_ads_json ================================== */}
                                                    <Box sx={{ mb: 3 }}>
                                                        <label htmlFor="custom_ads_json" className='form-label'>Custom Ads Json</label>

                                                        {customJsonError ? <Typography sx={{ color: 'red' }}>{customJsonError}</Typography> : ''}
                                                        <textarea
                                                            name="custom_ads_json"
                                                            id="custom_ads_json"
                                                            cols="30"
                                                            rows="10"
                                                            className="form-control"
                                                            value={customAdsJson}
                                                            style={{ color: 'gray' }}
                                                            onChange={(e) => { setCustomAdsJson(e.target.value); setCustomJsonError('') }}
                                                        />


                                                    </Box>

                                                </Box>


                                            </Grid>
                                            <Grid item lg={6} xs={12} >

                                                <Box sx={{ p: 1 }}>

                                                    {/* ================================== Right Grid extra_data_field ================================== */}
                                                    <Box sx={{ mb: 1 }}>
                                                        <label htmlFor="extra_data_field" className='form-label'>Extra Data Field</label>

                                                        {extraDataJsonError ? <Typography sx={{ color: 'red' }}>{extraDataJsonError}</Typography> : ''}

                                                        <textarea
                                                            name="extra_data_field"
                                                            id="extra_data_field"
                                                            cols="30"
                                                            rows="10"
                                                            className="form-control"
                                                            value={extraDataField}
                                                            style={{ color: 'gray', fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif" }}
                                                            onChange={(e) => { setExtraDataField(e.target.value); setExtraDataJsonError('') }}
                                                        />
                                                    </Box>

                                                </Box>

                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {/* ========================================= Extra data field and json field end here  =========================================*/}



                                    {/* ========================================= Save and cancel button for  Application Settings Tab start from here   =========================================*/}

                                    <Box textAlign='center'>
                                        <Button onClick={() => { handleAppsDetails(appData.id) }} sx={{ px: 3, mx: 1 }} variant="contained">Save</Button>
                                        <Button sx={{ px: 3, color: 'white', mx: 1 }} className="bg-secondary" onClick={() => { navigate('/app-lists') }}>cancel</Button>

                                    </Box>
                                    {/* ========================================= Save and cancel button for  Application Settings Tab End here   =========================================*/}


                                </Box>

                            </Box>

                        </TabPanel>

                        {/* ####################################################################################   Application Settings Tab End Here  #################################################################################### */}





                        {/* ####################################################################################   Application monetize Tab Start From Here  #################################################################################### */}

                        <TabPanel value={value} index={1}>


                            {/* application details and Ads  */}
                            <Box sx={{ mt: 2, px: 2 }}>
                                <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>

                                    <Grid container spacing={2} >

                                        {/* applications  */}
                                        <Grid item lg={6} xs={12} >

                                            <Box sx={{ p: 1 }}>

                                                {/* heading application  */}
                                                <Box sx={{ mb: 3 }}>
                                                    <Typography sx={{ p: 1, backgroundColor: 'lightblue', borderRadius: 1 }}>{appData.app_name}</Typography>
                                                </Box>

                                                {/* app logo  */}
                                                <Box >


                                                    {/* ======================= Grid for ads count and version name =======================  */}
                                                    <Grid container spacing={2}>
                                                        <Grid item lg={4} xs={12}>
                                                            <img src={appData.app_logo} alt="No Logo" height='150px' style={{ border: '1px solid lightgray', borderRadius: '10px', boxShadow: "2px 2px 5px gray" }} id='app_logo' name='app_logo' className='img-fluid' />
                                                        </Grid>


                                                        <Grid item lg={8} xs={12}>

                                                            {/* Ads Count and Select Ads Open Type */}
                                                            <Box sx={{ mt: 1 }}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item lg={8} xs={12}>
                                                                        {/* ads_open_type type  */}
                                                                        <Box sx={{ mb: 1 }}>
                                                                            <Box>

                                                                                <label htmlFor="extra_data_field" className='form-label'>Select Ads Open Type</label>


                                                                            </Box>

                                                                            <Select placeholder="Select App Open Type" sx={{ width: "100%", maxWidth: 400, minWidth: 40, height: 30 }} id="ads_open_type" name="ads_open_type" value={adsOpenType} onChange={(e) => setAdsOpenType(e.target.value)} >
                                                                                <MenuItem value='interstitial ads' >Interstitial Ads</MenuItem>
                                                                                <MenuItem value='app open ads'>App Open Ads</MenuItem>
                                                                            </Select>

                                                                        </Box>


                                                                    </Grid>
                                                                    <Grid item lg={4} xs={12}>
                                                                        <Box sx={{ mb: 1 }}>
                                                                            <label htmlFor="ads_count" className='form-label' style={{ display: 'inline-block' }} >Ads Count</label>

                                                                            {adsCountError ? <Typography sx={{ color: 'red' }}>{adsCountError}</Typography> : ''}

                                                                            <input type="number" name="ads_count" id="ads_count" className='form-control ' style={{ color: 'gray' }} value={adsCount} onChange={(e) => {
                                                                                if (e.target.value < 0) {
                                                                                    setAdsCountError('Count Should not be less then 0')
                                                                                } else {
                                                                                    setAdsCountError('')
                                                                                    setAdsCount(e.target.value)
                                                                                }
                                                                            }} />

                                                                        </Box>
                                                                    </Grid>

                                                                </Grid>



                                                                {/* <Box sx={{ mb: 2 }}>
                                                                    <label htmlFor="extra_data_field" className='form-label'>Ads Sequence Type</label>

                                                                    <Select placeholder="Select Ads Sequence Type" sx={{ width: "100%", maxWidth: 400, minWidth: 40, height: 30 }} id="ads_open_type" name="ads_open_type" value={adsTypeSequence} onChange={(e) => setAdsTypeSequence(e.target.value)} >
                                                                        <MenuItem value='Random Ads' >Random Ads </MenuItem>
                                                                        <MenuItem value='Fixed Ads'>Fixed Ads</MenuItem>
                                                                    </Select>
                                                                </Box> */}

                                                                {/* Show Ads Type fixed or random */}
                                                                {/* <Box sx={{ mt: 2 }}>
                                                                    <label htmlFor="" className='form-label'>Ads Sequence Type</label>
                                                                </Box> */}
                                                                <Box sx={{ mt: 2 }}>
                                                                    <Grid container spacing={2} >

                                                                        <Grid item lg={4} xs={12} >
                                                                            <Box >
                                                                                {adsTypeSequence === "Random Ads" ?
                                                                                    <input type="radio" id='adsSequenceTypeFixed' name='adsSequenceType' className='form-check-input' style={{ marginRight: 5 }} defaultChecked onClick={(e) => setAdsTypeSequence("Random Ads")} />
                                                                                    :
                                                                                    <input type="radio" id='adsSequenceTypeFixed' name='adsSequenceType' className='form-check-input' style={{ marginRight: 5 }} onClick={(e) => setAdsTypeSequence("Random Ads")} />

                                                                                }
                                                                                <label htmlFor="adsSequenceTypeFixed" className='form-label' >Random Ads</label>

                                                                            </Box>
                                                                        </Grid>

                                                                        <Grid item lg={4} xs={12} >
                                                                            <Box >
                                                                                {adsTypeSequence === "Fixed Ads" ?
                                                                                    <input type="radio" id='adsSequenceTypeRandom' name='adsSequenceType' className='form-check-input' style={{ marginRight: 5 }} defaultChecked onClick={(e) => setAdsTypeSequence("Fixed Ads")} />
                                                                                    :
                                                                                    <input type="radio" id='adsSequenceTypeRandom' name='adsSequenceType' className='form-check-input' style={{ marginRight: 5 }} onClick={(e) => setAdsTypeSequence("Fixed Ads")} />
                                                                                }
                                                                                <label htmlFor="adsSequenceTypeRandom" className='form-label'>Fixed Ads</label>
                                                                            </Box>
                                                                        </Grid>

                                                                        {/* <Grid item lg={4} xs={12} >
                                                                            <Box >
                                                                                {adsTypeSequence === "Manual Ads" ?

                                                                                    <input type="radio" id='adsSequenceTypeManula' name='adsSequenceType' className='form-check-input' style={{ marginRight: 5 }} defaultChecked onClick={(e) => setAdsTypeSequence("Manual Ads")} />
                                                                                    :
                                                                                    <input type="radio" id='adsSequenceTypeManula' name='adsSequenceType' className='form-check-input' style={{ marginRight: 5 }} onClick={(e) => setAdsTypeSequence("Manual Ads")} />
                                                                                }
                                                                                <label htmlFor="adsSequenceTypeManula" className='form-label'>Manual Ads</label>
                                                                            </Box>
                                                                        </Grid> */}
                                                                    </Grid>
                                                                </Box>


                                                                {/* Monetize Platform  */}
                                                                {adsType !== 'Test Ads' && (


                                                                    <CSSTransition in={true} appear={true} timeout={500} classNames="fade">
                                                                        <>
                                                                            <Typography sx={{ mt: 2 }} >
                                                                                <label htmlFor="" className="form-label">Monetize Platform</label>
                                                                                {monetizeName ? "" : <label htmlFor="" className="form-label text-danger">Please Select Valid Option </label>}
                                                                                <Box>
                                                                                    <Multiselect
                                                                                        isObject={false}
                                                                                        onRemove={(event) => { setMonetizeName(event) }}
                                                                                        onSelect={(event) => { setMonetizeName(event) }}
                                                                                        options={selectedOptions}
                                                                                        selectedValues={String(monetizeName).split(',')}
                                                                                        defaultValue={monetizeName}
                                                                                        showCheckbox={true}
                                                                                    />
                                                                                </Box>
                                                                            </Typography>



                                                                        </>
                                                                    </CSSTransition>

                                                                    // Show Ads Type fixed or random end here 
                                                                )}








                                                            </Box>

                                                        </Grid>


                                                    </Grid>
                                                </Box>

                                            </Box>
                                        </Grid>

                                        {/* Right section of Monetize  */}
                                        <Grid item lg={6} xs={12}>
                                            {/* ads heading  */}
                                            <Box sx={{ borderBottom: '1px solid gray', p: 1, mb: 3 }}>
                                                <Typography sx={{ fontWeight: 'bold' }}>Main Ads</Typography>
                                            </Box>

                                            <Grid container spacing={2} >


                                                <Grid item lg={6} xs={12} >


                                                    <Box sx={{ p: 1, mt: 1 }}>




                                                        <Box sx={{ mb: 3 }}>
                                                            <Box>
                                                                <label htmlFor="extra_data_field" className='form-label'>Select Ads Type</label>
                                                            </Box>

                                                            <Select placeholder="Select App Open Type" sx={{ width: "100%", maxWidth: 400, minWidth: 40, height: 30 }} id="ads_open_type" name="ads_open_type" value={adsType} onChange={(e) => setAdsType(e.target.value)} >
                                                                <MenuItem value='Test Ads' >Test Ads</MenuItem>
                                                                <MenuItem value='Main Ads'>Main Ads</MenuItem>
                                                            </Select>

                                                        </Box>



                                                    </Box>
                                                </Grid>
                                                <Grid item lg={6} xs={12} >

                                                    <Box sx={{ p: 1, mt: 1 }}>

                                                        {/* Test Ads Platform  */}
                                                        {/* if test ads selected in Ads Type ,then only this option visible  */}

                                                        <Box >
                                                            {adsType === 'Main Ads' ? '' :
                                                                <>
                                                                    <Box>
                                                                        <label htmlFor="extra_data_field" className='form-label'>Test Ads Platform</label>
                                                                    </Box>

                                                                    <Select placeholder="Select App Open Type" sx={{ width: "100%", maxWidth: 400, minWidth: 40, height: 30 }} id="testads_platform" name="testads_platform" value={testAdsPlatform} onChange={(e) => setTestAdsPlatform(e.target.value)} >

                                                                        {
                                                                            monetizePlatform.map((platform) => (
                                                                                <MenuItem value={platform.monetize_platformname} >{platform.monetize_platformname}</MenuItem>
                                                                            ))
                                                                        }
                                                                        {/* <MenuItem value="Random" sx={{ color: "red" }} >Random</MenuItem> */}

                                                                    </Select>
                                                                </>
                                                            }

                                                        </Box>
                                                    </Box>



                                                </Grid>



                                            </Grid>

                                            {/* Back press ads  */}

                                            <Box sx={{ mx: 1 }}>
                                                <Grid container spacing={2}>
                                                    <Grid item lg={8} xs={12}>
                                                        {/* ads_open_type type  */}
                                                        <Box sx={{ mb: 3 }}>
                                                            <Box>

                                                                <label htmlFor="extra_data_field" className='form-label'>Back Press Ads Type</label>


                                                            </Box>

                                                            <Select placeholder="Select App Open Type" sx={{ width: "100%", maxWidth: 400, minWidth: 40, height: 30 }} id="ads_open_type" name="ads_open_type" value={backPressAdsType} onChange={(e) => setBackPressAdsType(e.target.value)} >
                                                                <MenuItem value='interstitial ads' >Interstitial Ads</MenuItem>
                                                                <MenuItem value='app open ads'>App Open Ads</MenuItem>
                                                            </Select>

                                                        </Box>


                                                    </Grid>
                                                    <Grid item lg={4} xs={12}>
                                                        <Box sx={{ mb: 3 }}>
                                                            <label htmlFor="ads_count" className='form-label' style={{ display: 'inline-block' }} >Back Press Count</label>

                                                            {backPressAdsCountError ? <Typography sx={{ color: 'red' }}>{backPressAdsCountError}</Typography> : ''}

                                                            <input type="number" name="ads_count" id="ads_count" className='form-control ' style={{ color: 'gray' }} value={backPressAdsCount} onChange={(e) => {
                                                                if (e.target.value < 0) {
                                                                    setBackPressAdsCountError('Count Should not be less then 0')
                                                                } else {
                                                                    setBackPressAdsCountError('')
                                                                    setBackPressAdsCount(e.target.value)
                                                                }
                                                            }} />

                                                        </Box>



                                                    </Grid>

                                                </Grid>
                                            </Box>


                                        </Grid>

                                    </Grid>




                                    {/* ========================================================= Monetize tabs Start from here =========================================================  */}


                                    <Box sx={{ borderTop: 1, borderColor: 'divider' }}>
                                        {/* handlerChange */}

                                        <Tabs value={tabValue} textColor="primary" indicatorColor="primary" onChange={handleChange} variant="scrollable" sx={{ maxWidth: { xs: "60%", sm: "90%", md: "90%", lg: "90%" }, bgcolor: 'background.paper' }}>


                                            {
                                                monetizePlatform.map((platform) => (
                                                    <Tab label={platform.monetize_platformname} sx={{ textTransform: 'none', fontWeight: 'bold' }} key={platform.id}></Tab>
                                                ))
                                            }

                                        </Tabs>

                                        {
                                            monetizePlatform.map((platform) => (
                                                <TabPanel value={tabValue} index={srNo += 1} key={platform.id}>

                                                    {/* application details and Ads  */}
                                                    <Box sx={{ mx: 2, mb: 5 }}>
                                                        <Box sx={{ p: 2, borderRadius: 2, overflow: 'hidden' }}>

                                                            <Typography sx={{ p: 2, backgroundColor: 'lightblue', borderRadius: 1, width: "48%", mx: 1, mb: 2 }}>{platform.monetize_platformname}</Typography>

                                                            <Grid container spacing={2}>

                                                                {/* applications  */}
                                                                <Grid item lg={6} xs={12} >

                                                                    <Box sx={{ p: 1 }}>

                                                                        {/* app open ads  */}
                                                                        <Box sx={{ mb: 3 }}>
                                                                            <label className='form-label'>App Open Ads</label>


                                                                            <input
                                                                                type="text"
                                                                                defaultValue="Null"
                                                                                className='form-control'
                                                                                value={appOpenAds[platform.monetize_platformname] || ''}
                                                                                onChange={(e) => {
                                                                                    const platformName = platform.monetize_platformname;
                                                                                    setAppOpenAds((prevAds) => ({
                                                                                        ...prevAds,
                                                                                        [platformName]: e.target.value
                                                                                    }));
                                                                                }}
                                                                                style={{ color: 'gray' }}
                                                                            />




                                                                        </Box>


                                                                        {/* nativeAds ads  */}
                                                                        <Box sx={{ mb: 3 }}>
                                                                            <label className='form-label'>Native Ads</label>



                                                                            <input
                                                                                type="text"

                                                                                className='form-control'
                                                                                value={nativeAds[platform.monetize_platformname] || ''}
                                                                                onChange={(e) => {
                                                                                    const platformName = platform.monetize_platformname;
                                                                                    setNativeAds((prevAds) => ({
                                                                                        ...prevAds,
                                                                                        [platformName]: e.target.value
                                                                                    }));
                                                                                }}
                                                                                style={{ color: 'gray' }}
                                                                            />

                                                                        </Box>


                                                                        {/* rewarded_ads */}
                                                                        <Box sx={{ mb: 3 }}>
                                                                            <label className='form-label'>Rewarded Ads</label>

                                                                            <input
                                                                                type="text"

                                                                                className='form-control'
                                                                                value={rewardedAds[platform.monetize_platformname] || ''}
                                                                                onChange={(e) => {
                                                                                    const platformName = platform.monetize_platformname;
                                                                                    setRewardedAds((prevAds) => ({
                                                                                        ...prevAds,
                                                                                        [platformName]: e.target.value
                                                                                    }));
                                                                                }}
                                                                                style={{ color: 'gray' }}
                                                                            />

                                                                        </Box>


                                                                        {/* collapsible_ads */}
                                                                        <Box >
                                                                            <label className='form-label'>Collapsible Ads</label>
                                                                            {/* <input type="text" id='collapsible_ads' name='collapsible_ads' className='form-control' value={collapsibleAds !== null ? collapsibleAds : 'No Ads'} onChange={(e) => setCollapsibleAds(e.target.value)} style={{ color: 'gray' }} /> */}


                                                                            <input
                                                                                type="text"

                                                                                className='form-control'
                                                                                value={collapsibleAds[platform.monetize_platformname] || ''}
                                                                                onChange={(e) => {
                                                                                    const platformName = platform.monetize_platformname;
                                                                                    setCollapsibleAds((prevAds) => ({
                                                                                        ...prevAds,
                                                                                        [platformName]: e.target.value
                                                                                    }));
                                                                                }}
                                                                                style={{ color: 'gray' }}
                                                                            />

                                                                        </Box>




                                                                    </Box>
                                                                </Grid>

                                                                {/* Ads  */}
                                                                <Grid item lg={6} xs={12}>

                                                                    <Box sx={{ p: 1 }}>



                                                                        {/* banner ads  */}
                                                                        <Box sx={{ mb: 3 }}>
                                                                            <label className='form-label'>Banner Ads</label>
                                                                            {/* <input type="text" id='banner_ads' name='banner_ads' className='form-control' value={bannerAds !== null ? bannerAds : 'No Ads'} onChange={(e) => setBannerAds(e.target.value)} style={{ color: 'gray' }} /> */}

                                                                            <input
                                                                                type="text"

                                                                                className='form-control'
                                                                                value={bannerAds[platform.monetize_platformname] || ''}
                                                                                onChange={(e) => {
                                                                                    const platformName = platform.monetize_platformname;
                                                                                    setBannerAds((prevAds) => ({
                                                                                        ...prevAds,
                                                                                        [platformName]: e.target.value
                                                                                    }));
                                                                                }}
                                                                                style={{ color: 'gray' }}
                                                                            />

                                                                        </Box>



                                                                        {/* interstitial_ads ads  */}
                                                                        <Box sx={{ mb: 3 }}>
                                                                            <label className='form-label'>Interstitial Ads</label>
                                                                            {/* <input type="text" id='interstitial_ads' name='interstitial_ads' className='form-control' value={interstitialAds !== null ? interstitialAds : 'No Ads'} onChange={(e) => setInterstitialAds(e.target.value)} style={{ color: 'gray' }} /> */}

                                                                            <input
                                                                                type="text"

                                                                                className='form-control'
                                                                                value={interstitialAds[platform.monetize_platformname] || ''}
                                                                                onChange={(e) => {
                                                                                    const platformName = platform.monetize_platformname;
                                                                                    setInterstitialAds((prevAds) => ({
                                                                                        ...prevAds,
                                                                                        [platformName]: e.target.value
                                                                                    }));
                                                                                }}
                                                                                style={{ color: 'gray' }}
                                                                            />

                                                                        </Box>



                                                                        {/* mobile_sticky_ads */}
                                                                        <Box >
                                                                            <label className='form-label' >Mobile Sticky Ads</label>
                                                                            {/* <input type="text" id='mobile_sticky_ads' name='mobile_sticky_ads' className='form-control' value={mobileStickyAds !== null ? mobileStickyAds : 'No Ads'} onChange={(e) => setMobileStickyAds(e.target.value)} style={{ color: 'gray' }} /> */}

                                                                            <input
                                                                                type="text"

                                                                                className='form-control'
                                                                                value={mobileStickyAds[platform.monetize_platformname] || ''}
                                                                                onChange={(e) => {
                                                                                    const platformName = platform.monetize_platformname;
                                                                                    setMobileStickyAds((prevAds) => ({
                                                                                        ...prevAds,
                                                                                        [platformName]: e.target.value
                                                                                    }));
                                                                                }}
                                                                                style={{ color: 'gray' }}
                                                                            />

                                                                        </Box>


                                                                    </Box>
                                                                </Grid>
                                                            </Grid>

                                                        </Box>

                                                    </Box>

                                                </TabPanel>
                                            ))
                                        }



                                    </Box>

                                    <Box textAlign='center'>
                                        <Button onClick={() => { handleAppsDetails(appData.id) }} sx={{ mx: 1 }} variant="contained">Save</Button>
                                        <Button sx={{ px: 3, color: 'white', mx: 1 }} className="bg-secondary" onClick={() => { navigate('/app-lists') }}>cancel</Button>

                                    </Box>



                                </Box>

                            </Box >



                        </TabPanel >
                        {/* ####################################################################################   Application monetize Tab End Here #################################################################################### */}

                        {/* ####################################################################################   Notifications Settings Tab Start From Here #################################################################################### */}

                        <TabPanel value={value} index={2}>
                            {/* application details and Ads  */}
                            <Box sx={{ mt: 2, mb: 2, px: 2 }}>
                                <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>


                                    {/* ========================================= applications details Grid start here  =========================================  */}
                                    <Grid container spacing={2}>

                                        {/* ====================== Left applications details Grid ======================  */}
                                        <Grid item lg={4} xs={12} >

                                            <Box sx={{ p: 1 }}>

                                                {/* heading application  */}
                                                <Box sx={{ mb: 3 }}>
                                                    <Typography sx={{ p: 1, backgroundColor: 'lightblue', borderRadius: 1 }}>{appData.app_name}</Typography>
                                                </Box>

                                                {/* =================================================================== */}

                                                <Box sx={{ my: 3, pb: 1 }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item lg={4} md={4} xs={12}>

                                                            <img src={appData.app_logo} alt="No Logo" height='150px' style={{ border: '1px solid lightgray', borderRadius: '10px', boxShadow: "2px 2px 5px gray" }} id='app_logo' name='app_logo' className='img-fluid' />

                                                        </Grid>

                                                        <Grid item lg={8} md={8} xs={12}>
                                                            <Box sx={{ px: 2 }}>
                                                                <Box sx={{ mb: 3 }}>
                                                                    <label htmlFor="" className="form-label">Application Version</label>
                                                                    <Box>
                                                                        <input type="text" name="version_name" id="version_name" className='form-control w-50' style={{ color: 'gray' }} value={versionName} step="0.0"
                                                                            onChange={(e) => {
                                                                                if (e.target.value < 0) {
                                                                                    setVersionName(versionName)
                                                                                } else {
                                                                                    setAdsCountError('')
                                                                                    setVersionName(e.target.value)
                                                                                }
                                                                            }} />

                                                                    </Box>


                                                                </Box>

                                                                <Box sx={{ mb: 3 }}>
                                                                    {appData.app_status === 'Not Published'
                                                                        ?
                                                                        <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5, width: 150 }} textAlign='center' style={{ color: 'gray' }}>{appData.app_status}</Typography>
                                                                        :
                                                                        appData.app_status === 'Published'
                                                                            ?
                                                                            <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, width: 150 }} textAlign='center' style={{ color: 'gray' }}>{appData.app_status}</Typography>
                                                                            :
                                                                            <Typography sx={{ boxShadow: '1px 2px 8px red', display: 'inline-block', p: 1, borderRadius: 5, width: 150 }} textAlign='center' style={{ color: 'gray' }}>{appData.app_status}</Typography>

                                                                    }

                                                                </Box>
                                                            </Box>


                                                        </Grid>


                                                    </Grid>

                                                </Box>


                                                {/* ========================== below app logo  */}
                                                <Grid container spacing={2}>

                                                    <Grid item lg={6} xs={12} >
                                                        <Box sx={{ mb: 3 }}>
                                                            <Typography >
                                                                {/* <span style={{ fontWeight: 'bold' }}>Application Platform   </span> */}
                                                                <label className='form-label'>Application Platform </label>
                                                                {/* <span style={{ color: 'gray' }}>{appData.app_platform}</span> */}
                                                                <Typography sx={{ boxShadow: '1px 2px 8px gray', display: 'inline-block', mx: 2, px: 2, py: 1, borderRadius: 5 }} style={{ color: 'gray' }}>{appData.app_platform}</Typography>

                                                            </Typography>
                                                        </Box>


                                                        {/* Application DOwnloads  */}
                                                        <Box sx={{ mb: 3 }}>
                                                            <Typography sx={{ mt: 1 }}>
                                                                {/* <span style={{ fontWeight: 'bold' }}>Application Downloads : </span> */}
                                                                <label className='form-label me-2'>Application Downloads : </label>

                                                                <input type="text" id='downloads' name='downloads' className='form-control w-25 d-inline' value={appData.downloads} readOnly style={{ color: 'gray' }} />
                                                            </Typography>
                                                        </Box>

                                                    </Grid>

                                                    {/* application status  */}
                                                    <Grid item lg={6} xs={12} >
                                                        <Box sx={{ mx: 3 }}>
                                                            <Typography sx={{ mt: 1, fontWeight: 'bold' }}>Created Date </Typography>
                                                            <Typography sx={{ mt: 1, fontSize: 11 }} style={{ color: 'gray' }}>{moment(appData.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                        </Box>

                                                    </Grid>
                                                </Grid>

                                                {/* this section is seperate from other grids  */}
                                                {/* application added by  */}
                                                <Box sx={{ mb: 3 }}>
                                                    <label htmlFor="package_name" className='form-label'>Application Added By</label>
                                                    <input type="text" id='package_name' name='package_name' className='form-control' value={appData.added_user} readOnly style={{ color: 'gray' }} />

                                                </Box>


                                            </Box>


                                        </Grid>


                                        {/* ====================== Right applications Notification Details  ======================  */}

                                        <Grid item lg={8} xs={12}>

                                            {/* ========= this part notification panel component start from here */}


                                            <NotificationPanel package_name={appData.package_name} />
                                            {/* ========= this part notification panel component end here */}


                                        </Grid>
                                    </Grid>
                                    {/* ========================================= applications details Grid end here =========================================  */}
                                </Box>

                                {/* Notification History  */}




                            </Box>
                        </TabPanel >

                        {/* ####################################################################################   Notifications Settings Tab End Here #################################################################################### */}

                    </Box >
                }

            </Box >
        </>
    )
}

export default ApplicationDetails



