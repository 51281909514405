



import { Box, Grid, Typography, Button, CircularProgress } from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useGetAppDownloadStatusQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { useEffect, useState } from "react";
import TodayPerformApps from "./TodayPerformApps";
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination } from '@mui/material';
import moment from 'moment/moment';
import { useSelector, useDispatch } from 'react-redux';


// todays app data 
import { useGetAppTodayDownloadsQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";

// yesterday app data
import { useGetAppYesterdayDownloadsQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi";
import YesterdayPerformApps from "./YesterdayPerformApps";


const Dashboard = () => {
  const { access_token } = useSelector(state => state.auth);

    const [appDownloadCount, setAppDownloadCount] = useState('');
    const { data: appDownloads, isSuccess, refetch: allDownloadsCount, isLoading } = useGetAppDownloadStatusQuery(access_token);

    useEffect(() => {
        if (appDownloads && isSuccess) {
            setAppDownloadCount(appDownloads);
            allDownloadsCount();
        }
    }, [appDownloads, isSuccess]);

    const handleReload = () => {
        allDownloadsCount();
        TodaysData();
        YesterdayData();
        setShowLoadingAnimation(true);
        setTimeout(() => {
            setShowLoadingAnimation(false);
        }, 1000);

    };

    // ======================================  todays app data =================================================== 
    let srNo = 0;

    const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);
    const [todaysAppDownload, setTodaysAppDownload] = useState('');
    const { data: todaysApp, isSuccess: isTodaySuccessData, refetch: TodaysData, isLoading: isTodayDataLoading } = useGetAppTodayDownloadsQuery();

    useEffect(() => {
        if (todaysApp && isTodaySuccessData) {
            setTodaysAppDownload(todaysApp);
            TodaysData();
            setTimeout(() => {
                setShowLoadingAnimation(false);
            }, 1000);
        }
    }, [todaysApp, isTodaySuccessData]);

    const handlePlayStoreClick = (package_name) => {
        window.open(`https://play.google.com/store/apps/details?id=${package_name}`);
    };

    // ======================================  yesterday app data =================================================== 
    let srNo1 = 0;

    // const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);
    const [yesterdaysAppDownload, setYesterdaysAppDownload] = useState('');
    const { data: yesterdaysApp, isSuccess: isYesterdaySuccessData, refetch: YesterdayData, isLoading: isYesterdayDataLoading } = useGetAppYesterdayDownloadsQuery();

    useEffect(() => {
        if (yesterdaysApp && isYesterdaySuccessData) {
            setYesterdaysAppDownload(yesterdaysApp);
            YesterdayData();
            setTimeout(() => {
                setShowLoadingAnimation(false);
            }, 1000);
        }
    }, [yesterdaysApp, isYesterdaySuccessData]);

    // console.log('yesterdaysApp :',yesterdaysApp)
    // console.log('todaysAppDownload :', todaysAppDownload)

    return (
        <>
            <Box>
                <Box sx={{ mx: 2, mt: 2 }}>
                    <div>
                        <Typography color="primary">Dashboard</Typography>
                        <hr color="lightgray" />
                    </div>
                </Box>

                {/* dashboard */}
                <Box sx={{ mx: 2, mb: 3 }}>
                    <Box textAlign='right' sx={{ my: 1 }}>
                        <Button sx={{ textTransform: 'none' }} onClick={handleReload}><ReplayIcon />Reload Data</Button>
                    </Box>

                    <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>
                        <Typography sx={{ mx: 1, }} style={{ display: 'inline-block' }} color="primary">General Report</Typography>

                        <Grid container spacing={2}>
                            <Grid item lg={6} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item lg={6} xs={12}>
                                        {/* today total download downloads */}
                                        <Box style={{ width: '90%' }} sx={{ boxShadow: '2px 2px 8px gray', p: 2, px: 4, borderRadius: 2, m: 2, display: 'inline-block' }}  >
                                            <Box sx={{ mb: 1 }}>
                                                <DownloadIcon color="primary" />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ fontSize: 25 }} color="primary">
                                                    {appDownloadCount.current_day_count}
                                                </Typography>
                                            </Box>
                                            <Typography color='gray'>Today Total Downloads</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        {/* yesterday total download downloads */}
                                        <Box style={{ width: '90%' }} sx={{ boxShadow: '2px 2px 8px gray', p: 2, px: 4, borderRadius: 2, m: 2, display: 'inline-block' }}  >
                                            <Box sx={{ mb: 1 }}>
                                                <DownloadDoneIcon color="primary" />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ fontSize: 25 }} color="primary">
                                                    {appDownloadCount.yesterday_count}
                                                </Typography>
                                            </Box>
                                            <Typography color='gray'>Yesterday Total Downloads</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item lg={6} xs={12}>
                                        {/* today total download downloads */}
                                        <Box style={{ width: '90%' }} sx={{ boxShadow: '2px 2px 8px gray', p: 2, px: 4, borderRadius: 2, m: 2, display: 'inline-block' }}  >
                                            <Box sx={{ mb: 1 }}>
                                                <DateRangeIcon color="primary" />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ fontSize: 25 }} color="primary">
                                                    {appDownloadCount.current_month_count}
                                                </Typography>
                                            </Box>
                                            <Typography color='gray'>This Month Total Downloads</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        {/* yesterday total download downloads */}
                                        <Box style={{ width: '90%' }} sx={{ boxShadow: '2px 2px 8px gray', p: 2, px: 4, borderRadius: 2, m: 2, display: 'inline-block' }}  >
                                            <Box sx={{ mb: 1 }}>
                                                <CalendarTodayIcon color="primary" />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ fontSize: 25 }} color="primary">
                                                    {appDownloadCount.last_month_count}
                                                </Typography>
                                            </Box>
                                            <Typography color='gray'>Last Month Total Downloads</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {/* ========================================================================   todays best perform apps ========================================================================    */}
                <Box>
                    <Box sx={{ mx: 2, mt: 2, mb: 3 }}>

                        {/* <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>
                            <Box sx={{ my: 2 }}>
                                <Typography color="primary">Today's App Performance</Typography>
                            </Box>
                            <Box className="table-wrapper" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                                {isTodayDataLoading || showLoadingAnimation ? (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                                        <CircularProgress />
                                        <Typography sx={{ ml: 2 }}>Loading apps...</Typography>
                                    </Box>
                                ) : (
                                    <TableContainer>
                                        <Table className='table table-striped'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><Typography>App Id</Typography></TableCell>
                                                    <TableCell><Typography>App Logo</Typography></TableCell>
                                                    <TableCell><Typography>Total Downloads</Typography></TableCell>
                                                    <TableCell><Typography>App Name</Typography></TableCell>
                                                    <TableCell><Typography>Other Details</Typography></TableCell>
                                                    <TableCell><Typography>App Status</Typography></TableCell>
                                                    <TableCell><Typography>Assign Ads</Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>


                                                {
                                                    todaysAppDownload[0] ?

                                                        todaysAppDownload.map(app => (


                                                            <TableRow key={app.id}>
                                                                <TableCell>{app.id}</TableCell>
                                                                <TableCell>
                                                                    <img src={app.app_logo} alt={app.title} height='60px' className='border border-1 rounded-circle' style={{ boxShadow: "2px 2px 5px gray" }} />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 3, px: 2 }}>
                                                                        {app.downloads}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>{app.app_name}</TableCell>
                                                                <TableCell>
                                                                    <Typography
                                                                        sx={{ backgroundColor: 'lightblue', borderRadius: 1, p: 1 }}
                                                                        style={{ cursor: 'pointer' }}
                                                                        title="Click to checkout on Play Store"
                                                                        onClick={() => { handlePlayStoreClick(app.package_name) }}
                                                                    >
                                                                        {app.package_name}
                                                                    </Typography>
                                                                    <Typography sx={{ fontSize: '11px' }}>Created date: {moment(app.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                                    <Typography sx={{ fontSize: '11px' }}>Last update: {moment(app.updated_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {app.app_status === 'Not Published' ? (
                                                                        <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5 }} style={{ color: 'gray' }}>
                                                                            {app.app_status}
                                                                        </Typography>
                                                                    ) : (
                                                                        <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} style={{ color: 'gray' }}>
                                                                            {app.app_status}
                                                                        </Typography>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {app.ads_type === 'Main Ads' ? (
                                                                        <Typography style={{ textShadow: '1px 3px 5px green' }}>{app.ads_type}</Typography>
                                                                    ) : (
                                                                        <Typography style={{ textShadow: '1px 3px 5px orange' }}>{app.ads_type}</Typography>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>

                                                        ))


                                                        :
                                                        <TableRow >

                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell><Typography sx={{ p: 1 }}>No Downloads</Typography></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    // <p>No Downloads </p>

                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Box>
                        </Box> */}


                        <TodayPerformApps showLoadingAnimation = {showLoadingAnimation} />

                    </Box>
                </Box >


                {/* ========================================================================   yesterday best perform apps ========================================================================    */}
                <Box>
                    <Box sx={{ mx: 2, mt: 2, mb: 8 }}>

                        {/* <Box sx={{ minWidth: 275, boxShadow: '2px 2px 8px gray', p: 2, borderRadius: 2, overflow: 'hidden' }}>
                            <Box sx={{ my: 2 }}>
                                <Typography color="primary">Yesterday's App Performance</Typography>
                            </Box>


                            <Box className="table-wrapper" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                                {isTodayDataLoading || showLoadingAnimation ? (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                                        <CircularProgress />
                                        <Typography sx={{ ml: 2 }}>Loading apps...</Typography>
                                    </Box>
                                ) : (
                                    <TableContainer>
                                        <Table className='table table-striped'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><Typography>App Id</Typography></TableCell>
                                                    <TableCell><Typography>App Logo</Typography></TableCell>
                                                    <TableCell><Typography>Total Downloads</Typography></TableCell>
                                                    <TableCell><Typography>App Name</Typography></TableCell>
                                                    <TableCell><Typography>Other Details</Typography></TableCell>
                                                    <TableCell><Typography>App Status</Typography></TableCell>
                                                    <TableCell><Typography>Assign Ads</Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    yesterdaysApp[0] ?
                                                        yesterdaysApp.map(app => (
                                                            <TableRow key={app.id}>
                                                                <TableCell>{app.id}</TableCell>
                                                                <TableCell>
                                                                    <img src={app.app_logo} alt={app.title} height='60px' className='border border-1 rounded-circle' style={{ boxShadow: "2px 2px 5px gray" }}/>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 3, px: 2 }}>
                                                                        {app.downloads}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>{app.app_name}</TableCell>
                                                                <TableCell>
                                                                    <Typography
                                                                        sx={{ backgroundColor: 'lightblue', borderRadius: 1, p: 1 }}
                                                                        style={{ cursor: 'pointer' }}
                                                                        title="Click to checkout on Play Store"
                                                                        onClick={() => { handlePlayStoreClick(app.package_name) }}
                                                                    >
                                                                        {app.package_name}
                                                                    </Typography>
                                                                    <Typography sx={{ fontSize: '11px' }}>Created date: {moment(app.created_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                                    <Typography sx={{ fontSize: '11px' }}>Last update: {moment(app.updated_at).format('MMMM Do YYYY, h:mm a')}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {app.app_status === 'Not Published' ? (
                                                                        <Typography sx={{ boxShadow: '1px 2px 8px orange', display: 'inline-block', p: 1, borderRadius: 5 }} style={{ color: 'gray' }}>
                                                                            {app.app_status}
                                                                        </Typography>
                                                                    ) : (
                                                                        <Typography sx={{ boxShadow: '1px 2px 8px green', display: 'inline-block', p: 1, borderRadius: 5, px: 3 }} style={{ color: 'gray' }}>
                                                                            {app.app_status}
                                                                        </Typography>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {app.ads_type === 'Main Ads' ? (
                                                                        <Typography style={{ textShadow: '1px 3px 5px green' }}>{app.ads_type}</Typography>
                                                                    ) : (
                                                                        <Typography style={{ textShadow: '1px 3px 5px orange' }}>{app.ads_type}</Typography>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                        :
                                                        <TableRow >

                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell><Typography sx={{ p: 1 }}>No Downloads</Typography></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>

                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Box>

                            


                        </Box> */}

                        <YesterdayPerformApps showLoadingAnimation = {showLoadingAnimation} />
                    </Box>
                </Box >


            </Box >
        </>
    );
}

export default Dashboard;














































