import { Box, Typography, Grid, Select, MenuItem, Alert } from "@mui/material"
import { useEffect, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { useGetpublishPlatformQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"
import { useAddApplicationMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"

import { useGetLoggedUserQuery } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"
import { getToken } from "../../ApiCalls/AuthenticationsApi/services/LocalStorageService"
// import { useSelector, useDispatch } from 'react-redux';


const AddApplication = () => {
    

    const [appname, setAppname] = useState('');
    const [packagename, setPackagename] = useState('');
    const [applogo, setApplogo] = useState(null);
    const [publishPlatform, setPublishPlatform] = useState([]);   // Store platform data from api
    const [platformtype, setPlatformtype] = useState('');
    const [showChangePass, setShowChangePass] = useState(false)

    // call APi to get user data 
    const [userData, setUserData] = useState({
        email: '',
        name: '',
        is_admin: ''
    })


    const [error, setError] = useState({
        state: false,
        msg: '',
        error: ''
    })

    // get loge in user details 
    const { access_token } = getToken()
    const { data: userDetails, refetch: LoadUserData } = useGetLoggedUserQuery(access_token)

    // get publish platform
    const { data, isSuccess, refetch } = useGetpublishPlatformQuery(access_token);

    // post application 
    const [postApp, { isLoading, isError }] = useAddApplicationMutation()

    const navigate = useNavigate()

    useEffect(() => {
        if (data && isSuccess) {
            // console.log('data :', data)
            setPublishPlatform(data)


            refetch()
        }
    }, [data, isSuccess])



    useEffect(() => {
        if (userDetails) {
            // console.log('userDetails :',userDetails)
            setUserData({
                email: userDetails.email,
                name: userDetails.name,
                is_admin: userDetails.is_admin
            })


            LoadUserData()
        }
    }, [userDetails])



    const handleAppnameChange = (e) => {
        setAppname(e.target.value)
    }

    const handlePackagenameChange = (e) => {
        setPackagename(e.target.value)
    }

    const handleApplogoChange = (e) => {
        setApplogo(e.target.files[0]);
    };

    const handleplatformtypeChange = (event) => {
        setPlatformtype(event.target.value)
    }

    // console.log(publishPlatform)

    const posthandler = async (e) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append('app_name', appname)
        formData.append('package_name', packagename)
        formData.append('app_logo', applogo)
        formData.append('app_platform', platformtype)
        formData.append('show_ads', true)
        formData.append('added_user', userData.name)
        // formData.append('added_user', "Dev")


        // console.log('user name :', userData.name)
        // console.log(formData)

        const res = await postApp({formData,access_token});
        // console.log('res :',res)
        // Handle successful post
        if (res.data) {
            navigate('/app-lists')
        }
        if (res.error) {
            if (res.error.data){
                setError({
                    state: true,
                    msg: res.error.data.package_name[0],
                    error: 'error'
                })
            }

            if (res.error.error){
                setError({
                    state: true,
                    msg: "Image icon size too larg",
                    error: 'error'
                })
            }    
        }




    }

    const onClose = () => {
        setError({
            state: false,
            msg: "",
            error: ''
        })
    }


    return (
        <>
            <Box>
                <Box sx={{ mx: 2, mt: 2 }} >
                    <div>
                        <Typography color="primary"><NavLink to='/app-lists' sx={{ color: 'primary' }}>App Lists</NavLink> / Add Application</Typography>
                        <hr color="lightgray" />
                    </div>
                </Box>



                <Box sx={{ mx: 2, mt: 2 }}>

                    <Grid container spacing={2}>

                        <Grid item xs={2}>

                        </Grid>

                        <Grid item lg={8} xs={12}>

                            <Box sx={{ my: 2, px: 4 }}>
                                <Typography color='primary' style={{ fontSize: 'medium' }}>Add Application</Typography>
                            </Box>
                            <Box sx={{ p: 5, boxShadow: 5, borderRadius: 2 }}>

                                {error.state ? <Alert severity={error.error} onClose={onClose}>{error.msg}</Alert> : ''}

                                <form onSubmit={posthandler}>

                                    {/* application name  */}
                                    <div className="mb-3">
                                        <label htmlFor="appname" className="form-label">Application Name:</label>
                                        <input type="text" id="appname" name="appname" value={appname} onChange={handleAppnameChange} className="form-control" />
                                    </div>

                                    {/* package name  */}
                                    <div className="mb-3">
                                        <label htmlFor="packagename" className="form-label">package Name:</label>
                                        <input type="text" id="packagename" name="packagename" value={packagename} onChange={handlePackagenameChange} className="form-control" />
                                    </div>

                                    {/* app logo  */}
                                    <div className="mb-3">
                                        <label htmlFor="applogo" className="form-label">Application Logo:</label>
                                        <input type="file" id="applogo" name="applogo" accept="image/*" onChange={handleApplogoChange} className="form-control" />
                                    </div>

                                    {/* publish platform  */}
                                    <div className="mb-3">
                                        <label htmlFor="text" className="form-label">Platform Type:</label>

                                        <div>
                                            <Select placeholder="Select App Platform" sx={{ width: 400, height: 30, }} value={platformtype} onChange={handleplatformtypeChange} >

                                                {publishPlatform.map((data) => (

                                                    <MenuItem key={data.id} value={data.platformname}  >{data.platformname}</MenuItem>
                                                ))}

                                            </Select>
                                        </div>

                                    </div>

                                    <button type="submit" disabled={!appname || !packagename || !applogo || !platformtype} className="btn btn-success px-5">
                                        {isLoading ? 'Adding...' : 'Add'}

                                    </button>
                                    <button className="btn btn-secondary px-5 mx-2" onClick={() => { navigate('/app-lists') }}>
                                        Cancel
                                    </button>
                                    {error.state ? '' : isError && <p style={{ color: 'red' }}>Error Adding platform. Please check input data.</p>}

                                </form>

                            </Box>
                        </Grid>


                        <Grid item xs={2}>

                        </Grid>

                    </Grid>

                </Box>
            </Box>



        </>
    )
}

export default AddApplication
