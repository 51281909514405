

import React, { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, CssBaseline, Alert, AlertTitle, CircularProgress, Typography } from '@mui/material';
import UserLogin from "./Components/Auth/UserLogin";
import Layout from './MainPageLayout/Layout';
import SideBarRoutes from '../src/AppRoutes/SideBarRoutes';
import ResetPassword from './Components/Auth/ResetPassword';
import EmailResetPasswordConfirm from './Components/Auth/EmailResetPasswordConfirm';
import { unSetUserToken } from './ApiCalls/AuthenticationsApi/features/authSlice';
import { removeToken, getToken } from './ApiCalls/AuthenticationsApi/services/LocalStorageService';
// import { getToken, storeToken } from "../../ApiCalls/AuthenticationsApi/services/LocalStorageService"

// import { useGetLoggedUserQuery } from "../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { useGetLoggedUserQuery } from './ApiCalls/AuthenticationsApi/services/userAuthApi';

import './App.css';
import DashboardRoutes from './NavigationBar/DashboardRoutes';

function App() {
  const { access_token } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const inactiveTime = 60000 * 15; // 15 minutes in milliseconds
  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const resetTimer = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(handleLogout, inactiveTime);
  };

  const handleLogout = () => {
    // Remove token from local storage
    dispatch(unSetUserToken({ access_token: null }));
    removeToken();
    localStorage.removeItem('firstLaunch');
    localStorage.removeItem('previousUrl');
    navigate('/');
  };

  useEffect(() => {
    const resetTimerAndCleanup = () => {
      resetTimer();
      window.addEventListener('mousemove', resetTimer);
      window.addEventListener('keydown', resetTimer);
      window.addEventListener('touchstart', resetTimer);
    };

    resetTimerAndCleanup();

    return () => {
      clearTimeout(timerRef.current);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('touchstart', resetTimer);
    };
  }, []);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);

    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);


  // check token validity 
  const { data, isSuccess, refetch, isLoading } = useGetLoggedUserQuery(access_token)
  const [validToken, setValidToken] = useState(false)
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);

  useEffect(() => {
    setShowLoadingAnimation(true)
    if (isSuccess && !isLoading) {

      setValidToken(true)
    }

    setTimeout(() => {
      setShowLoadingAnimation(false)

    }, 1000)
    refetch()


  }, [data])


  // console.log(data)

  return (



    <>

      <CssBaseline />

      {isOnline ? (

        <div className="data-table-container">
          {isLoading || showLoadingAnimation ?
            (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                <CircularProgress />
                <Typography sx={{ ml: 2, py: 50 }}>Loading ...</Typography>
              </Box>
            ) :

            <Routes Routes >
              <Route path="*" element={access_token && validToken && isSuccess ? <Layout /> : <UserLogin />} >
                {/* <Route path="/*" element={!access_token && !validToken && !isSuccess? <Layout /> : <Navigate to="/" /> } /> */}
                <Route path="/*" element={<Layout />} />

              </Route>

              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/email-reset-password-confirmation" element={<EmailResetPasswordConfirm />} />
              <Route path="/api/user/reset-password/:id/:token" element={<EmailResetPasswordConfirm />} />

              <Route path="*" element={<h1>Page not found</h1>} />
            </Routes>
          }

        </div >

      ) : (
        <Box sx={{ padding: '16px' }}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Internet connection is not available
          </Alert>
        </Box>
      )
      }

    </>
  );
}

export default App;






