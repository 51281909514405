
// import { useEffect, useState } from 'react';
// import { NavLink, Outlet, useNavigate } from 'react-router-dom';
// import { motion, AnimatePresence } from 'framer-motion';
// import { Box, CssBaseline, Typography } from '@mui/material';
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import ReorderIcon from '@mui/icons-material/Reorder';
// import FormatShapesIcon from '@mui/icons-material/FormatShapes';
// import AppsIcon from '@mui/icons-material/Apps';
// import ListIcon from '@mui/icons-material/List';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
// import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
// import QueryStatsIcon from '@mui/icons-material/QueryStats';
// import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

// import Navbar from './Navbar';
// import DashboardRoutes from './DashboardRoutes';

// // get user data 
// import { useGetLoggedUserQuery } from "../ApiCalls/AuthenticationsApi/services/userAuthApi";
// import { getToken } from "../ApiCalls/AuthenticationsApi/services/LocalStorageService";
// import { Menu } from 'antd'


// function Sidebar() {
//     // call APi to get user data 
//     const [userData, setUserData] = useState({
//         email: '',
//         name: '',
//         is_admin: ''
//     })

//     const { access_token } = getToken()
//     const { data, isSuccess } = useGetLoggedUserQuery(access_token)


//     useEffect(() => {
//         if (data && isSuccess) {

//             setUserData({
//                 email: data.email,
//                 name: data.name,
//                 is_admin: data.is_admin
//             })
//         }
//     }, [data, isSuccess])


//     const navigate = useNavigate()

//     const menuItems = [
//         {
//             key: '/',
//             label: 'Dashboard',
//             icon: <HomeOutlinedIcon />
//         },
//         {
//             key: '/platform',
//             label: 'Platform',
//             icon: <FormatShapesIcon />
//         },
//         {
//             key: '/app-lists',
//             label: 'App Lists',
//             icon: <AppsIcon />
//         }, {
//             key: '/api-lists',
//             label: 'Api Lists',
//             icon: <ListIcon />
//         }, {
//             key: '/test-ads',
//             label: 'Test Ad',
//             icon: <ContentCopyIcon />
//         }, {
//             key: '/change-platform',
//             label: 'Change Plateform',
//             icon: <PublishedWithChangesIcon />
//         }, {
//             key: '/app-activity-log',
//             label: 'App Activity Log',
//             icon: <QueryStatsIcon />
//         }, {
//             key: '/app-notifications',
//             label: 'App Notifications',
//             icon: <NotificationsNoneIcon />
//         }, {
//             key: '/user-profile',
//             label: 'Profile',
//             icon: <PersonOutlineOutlinedIcon />
//         }
//         // Add more routes for each section
//     ];

//     if (userData.is_admin) {
//         menuItems.push({
//             label: 'Users',
//             key: '/users',
//             icon:<PeopleOutlineIcon />
//         })
//     }
//     return (
//         <>
//             <div className='sideMenu '>
//                 <Menu
//                     onClick={(item) => {
//                         navigate(item.key)
//                     }}
//                     items={menuItems}
//                 />
//             </div>
//         </>
//     )
// }

// export default Sidebar


import { useEffect, useState } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, CssBaseline, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ReorderIcon from '@mui/icons-material/Reorder';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Menu } from 'antd';

import Navbar from './Navbar';
import DashboardRoutes from './DashboardRoutes';

// get user data
import { useGetLoggedUserQuery } from "../ApiCalls/AuthenticationsApi/services/userAuthApi";
import { getToken } from "../ApiCalls/AuthenticationsApi/services/LocalStorageService";

function Sidebar() {
  // call API to get user data
  const [userData, setUserData] = useState({
    email: '',
    name: '',
    is_admin: ''
  });

  const { access_token } = getToken();
  const { data, isSuccess } = useGetLoggedUserQuery(access_token);

  useEffect(() => {
    if (data && isSuccess) {
      setUserData({
        email: data.email,
        name: data.name,
        is_admin: data.is_admin
      });
    }
  }, [data, isSuccess]);

  const navigate = useNavigate();

  const menuItems = [
    {
      key: '/',
      label: 'Dashboard',
      icon: <HomeOutlinedIcon />
    },
    {
      key: '/platform',
      label: 'Platform',
      icon: <FormatShapesIcon />
    },
    {
      key: '/app-lists',
      label: 'App Lists',
      icon: <AppsIcon />
    },
    {
      key: '/api-lists',
      label: 'Api Lists',
      icon: <ListIcon />
    },
    {
      key: '/test-ads',
      label: 'Test Ad',
      icon: <ContentCopyIcon />
    },
    {
      key: '/change-platform',
      label: 'Change Platform',
      icon: <PublishedWithChangesIcon />
    },
    {
      key: '/app-activity-log',
      label: 'App Activity Log',
      icon: <QueryStatsIcon />
    },
    {
      key: '/app-notifications',
      label: 'App Notifications',
      icon: <NotificationsNoneIcon />
    },
    {
      key: '/user-profile',
      label: 'Profile',
      icon: <PersonOutlineOutlinedIcon />
    }
    // Add more routes for each section
  ];

  if (userData.is_admin) {
    menuItems.push({
      label: 'Users',
      key: '/users',
      icon: <PeopleOutlineIcon />
    });
  }

  return (
    <>
      <div className="sideMenu">
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={['/']}
          defaultOpenKeys={['/']}
          style={{ backgroundColor: 'white' }} // Set default menu background color
        >
          {menuItems.map((menuItem) => (
            <Menu.Item
              key={menuItem.key}
              icon={menuItem.icon}
              style={{ color: '#f38c16' }} // Set default menu text color
            >
              <NavLink
                to={menuItem.key}
                // activeClassName="activeMenuItem"
                style={{ color: '#f38c16' }} // Set default menu text color for NavLink
                activeStyle={{ color: '#ffffff', backgroundColor: '#f38c16' }} // Set active menu text color and background color
              >
                {menuItem.label}
              </NavLink>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </>
  );
}

export default Sidebar;
