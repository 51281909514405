
import { TextField, Button, Box, Alert, Typography } from "@mui/material"

import { useChangeUserPasswordMutation } from "../../ApiCalls/AuthenticationsApi/services/userAuthApi"
import { useState } from "react"
import { getToken } from "../../ApiCalls/AuthenticationsApi/services/LocalStorageService"

const ChangeUserPassword = () => {
    const [server_error, setServerError] = useState({})
    const [error, setError] = useState({
        state: false,
        msg: '',
        error: ''
    })

    const { access_token } = getToken()
    const [changeUserPassword] = useChangeUserPasswordMutation()

    const handlePasswordChange = async (e) => {
        e.preventDefault()

        const data = new FormData(e.currentTarget)
        const actualData = {
            password: data.get('password'),
            password2: data.get('password2')
        }

        const res = await changeUserPassword({ actualData, access_token })

        console.log(res)
        if (res.error) {
            if (res.error.data.errors.non_field_errors) {
                setError({
                    state: true,
                    msg: res.error.data.errors.non_field_errors[0],
                    error: 'error'
                })
                setTimeout(() => {
                    handleOnClose()
                }, 5000)
            }
            setServerError(res.error.data.errors)
        }
        if (res.data) {
            setError({
                state: true,
                msg: res.data.msg,
                error: 'success'
            })
            setTimeout(() => {
                handleOnClose();
            }, 3000)
            document.getElementById('change-password').reset()
        }
    }

    const handleOnClose = () => {
        setError({
            state: false,
            msg: '',
            error: ''
        })
    }

    return (
        <>

            <Box component='form' noValidate id="change-password" onSubmit={handlePasswordChange}>

                {error.error ? <Alert severity={error.error} onClose={handleOnClose}>{error.msg}</Alert> : ''}

                <TextField required fullWidth margin="normal" label='New password' id="password" name="password" size="small" type="password" />
                {server_error.password ? <Typography style={{ fontSize: 11, color: 'red' }}>{server_error.password[0]}</Typography> : ''}

                <TextField required fullWidth margin="normal" label='Confirm new password' id="password" name="password2" size="small" type="password" />
                {server_error.password2 ? <Typography style={{ fontSize: 11, color: 'red' }}>{server_error.password2[0]}</Typography> : ''}

                <Box alignItems='center' sx={{ my: 3 }}>
                    <Button type="submit" sx={{ textTransform: 'none', px: 4 }} variant="contained" >Change</Button>
                </Box>
            </Box>
        </>
    )
}

export default ChangeUserPassword
